import { getAuth } from 'firebase/auth';
import React from 'react';
import { Navbar, Container, Nav, Spinner } from 'react-bootstrap';
import { Fade } from 'react-reveal';
import logo from '../earlypath3.svg';


function NavbarTop({isSignedIn}) {
  return (
    <div>
      <Navbar id='navbar' bg='light' expand='lg' variant='light'>
        <Container fluid>
          <Navbar.Brand href='/'>
            <img
              src={logo}
              alt='logo'
              width='30px'
              height='30px'
              className='d-inline-block'
            />{' '}
          <div className='prim-black' style={{
            display: 'inline' 
          }}> EarlyPath</div> 
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse className='justify-content-end' id="basic-navbar-nav">
          <Nav className='me auto'>

        {/* {<Nav.Link className='navlink' href='/signin'>Profile</Nav.Link> } */}

            <Nav.Link className='navlink' href='/ai'>AI</Nav.Link>
            <Nav.Link className='navlink' href='/build-a-resume'>Build a Resume</Nav.Link>
            <Nav.Link className='navlink' href='/contact'>Contact Us</Nav.Link>
            <Nav.Link className='navlink' href='/jobs'>Opportunities</Nav.Link>
            <Nav.Link className='navlink' href='/story'>Our Story</Nav.Link>
           
          </Nav>
     </Navbar.Collapse>
          
        </Container>
      </Navbar>
    </div>
  );
}

export default NavbarTop;
