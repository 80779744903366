import React from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';

function ResumeBuilder() {
    const templates = [
        {
          title: 'Resume for Beginners',
          link: 'https://www.canva.com/design/DAFY-RcGIDI/JCgQTAMdaAZaP7_jHDcuuA/view?utm_content=DAFY-RcGIDI&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview',
          image: 'BasicResume1.png'
        },
        {
          title: 'Harvard Resume Template',
          link: 'https://docs.google.com/document/d/11Xu7QJr2iY8F1OAxkneAwIkEPhlymhOA/edit',
          image: 'HarvardResume1.png'
        },
        // Add more templates here
      ];
      
  const containerStyle = {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingTop: '129.4118%',
    paddingBottom: 0,
    boxShadow: '0 2px 8px 0 rgba(63, 69, 81, 0.16)',
    marginTop: '1.6em',
    marginBottom: '0.9em',
    overflow: 'hidden',
    borderRadius: '8px',
    willChange: 'transform',
  };

  const iframeStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    border: 'none',
    padding: 0,
    margin: 0,
  };

  return (
    <Container className="mt-5">
    <Row>
      <Col>
        <h1>Resume Templates</h1>
        <hr />
      </Col>
    </Row>
    <Row>
      {templates.map((template, index) => (
        <Col md={12} lg={4} key={index}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Img src={template.image}></Card.Img>
              <Card.Title>{template.title}</Card.Title>
              <Button variant="primary" href={template.link} target="_blank" rel="noopener noreferrer">
                View Template
              </Button>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  </Container>
  );
}

export default ResumeBuilder;
