import React, { useEffect, useState } from 'react';
import { Card, Col, FloatingLabel, Form, FormControl, InputGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Fade, Bounce, Zoom } from 'react-reveal';
import SearchBar from '../Components/SearchBar';
import { ParallaxLayer, Parallax } from '@react-spring/parallax';
import { useSpring, animated } from '@react-spring/web'
import { Waypoint } from 'react-waypoint'
import { AiOutlineArrowDown } from 'react-icons/ai';
import Jobs from './Jobs';
import Story from './Story';
import JobsTable from './JobsTable';
import CircleProgressBar from '../Components/CircleProgressBar';

function Home({ app, queryData, setQuery }) {
 
  const [showStats, setShowStats] = useState(false)
  return (
    <div>
      <div
        style={{

        }}
      >

        <Container fluid id='landing' className='centered'>
          <Container className=''>
            <Fade>
              <Row className='col-12 g-1 text-success header-text'>

                <Col className='centered'>
                  <h1>EarlyPath</h1>
                </Col>

              </Row>
              <Row className='col-12 g-1 text-light primBlack subheader-text'>
                <div className='centered'>Your path begins here.</div>
              </Row>
            </Fade>
            <center>
              <Row>
                <Col sm={12} className='centered text-light'>
                  <Fade bottom>

                    {/* <AiOutlineArrowDown className='scroll-arrow' /> */}
                  </Fade>
                </Col>
              </Row>

            </center>
            <p />

          </Container>

        </Container>
        <Container fluid className='general-page-clear our-goal' style={{ background: "#f0f0f0" }}>
          <Container>
            <Row>
              <Fade bottom>

                <Row className='m-2'>
                  <Waypoint
                    onEnter={() => setShowStats(true) }
                  />
                  <Col md={12} lg={6}>
                    <h2 className='gradient-text'>Our Goal</h2>
                    <h3 className='gradient-text'>We strive to connect high school students with vetted local businesses to help find amazing internship and job opportunities.</h3>
                  </Col>
                  <Col className='centered' md={12} lg={3}>
                    <Row>
                      <center>
                        <div style={{ height: 230, width: 230 }}>
                          {showStats ? <CircleProgressBar circleWidth={230} percentage={93} /> : ''}
                          
                        </div>
                      </center>
                    </Row>
                    <Row className='m-2'>

                      <center>
                        <h4 className='gradient-text'>
                          “Internships for high school students are hard to find without personal connections.”
                        </h4>
                      </center>
                    </Row>
                  </Col>
                  <Col md={12} lg={3}>
                    <center>
                      <div style={{ height: 230, width: 230 }}>
                      {showStats ? <CircleProgressBar circleWidth={230} percentage={95} /> : ''}
                      </div>
                    </center>
                    <Row className='m-2'>
                      <center>
                        <h4 className='gradient-text'>
                          "It would be helpful to have a platform that connects them with verified local job or internship opportunities."
                        </h4>
                      </center>
                    </Row>

                  </Col>

                  {/* <Col sm={2}>TEST</Col> */}
                </Row>
              </Fade>
            </Row>
          </Container>
        </Container>





        <SearchBar setQuery={setQuery} queryData={queryData} />
        <Container className='text-dark' >
          <hr />
          <Row>
            <center>
            <Col md={12} className='subheader-text'><h1>Search all Jobs</h1></Col>
            </center>
          </Row>
          <p />
          <Row>

            <JobsTable queryData={queryData} app={app} setQuery={setQuery} all={true} />

          </Row>

        </Container>

      </div>


    </div >

  );
}

export default Home;
