import { EmailAuthProvider, getAuth, GoogleAuthProvider } from 'firebase/auth';
import {StyledFirebaseAuth} from 'react-firebaseui';
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';


function LogInScreen({app, isSignedIn}) {
    const auth = getAuth(app);
   
    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
        // We will display Google and Facebook as auth providers.
        signInOptions: [
          new GoogleAuthProvider().providerId,
          new EmailAuthProvider().providerId
        ],
        callbacks: {
            // Avoid redirects after sign-in.
            signInSuccessWithAuthResult: () => false,
          },
        };
    
  if(isSignedIn === undefined) {
    return (
      <Container fluid className='genPage primBlack align-self-center'>      <Spinner animation="border" /></Container>

    )
  }
  if(!isSignedIn) {
  return (
    <div> 
         <Container fluid className='genPage primBlack align-self-center'>
             <Row>
                 <Col md={12}>
                   <center><h1>Log in</h1></center>
                </Col>
                <Col md={12}>
                    <StyledFirebaseAuth className='sign-in-btns' uiConfig={uiConfig} firebaseAuth={auth} />
                </Col>
             </Row>

         </Container>
    </div>
  )
  }
  else {
    return (
        <div> 
             <Container fluid className='genPage primBlack align-self-center'>
                 <Row>
                     <Col md={12}>
                       <center> 
                            <h1>Welcome {auth.currentUser.displayName}! You are now logged in!</h1>
                        </center>
                    </Col>
                    <Col md={12}>
                    <center>
                        <Button variant='dark' onClick={() => auth.signOut()}>Log out</Button>
                    </center>
                    </Col>
                 </Row>
    
             </Container>
        </div>
    ) 
  }
}

export default LogInScreen