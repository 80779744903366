import { getFirestore, collection, getDocs, where, query } from "firebase/firestore";
import SearchBar from '../Components/SearchBar';
import '../jobs.css'
import { Parallax } from '@react-spring/parallax';
import { useEffect, useState } from "react";
import { Button, Collapse, Table } from "react-bootstrap";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { Fade } from "react-reveal";


function JobsTable({ app, queryData, all }) {
    const [collapsed, setCollapsed] = useState({
        0: false
    })
    function searchAndRankObjects(searchQuery, jsonArray) {
        function calculateRelevancy(description, company, position, searchQuery) {
            const keywords = searchQuery.toLowerCase().split(' ');
            let relevancy = 0;

            [description, company].forEach(field => {
                keywords.forEach(keyword => {
                    if (field && field.toLowerCase().includes(keyword)) {
                        relevancy++;
                    }
                });
            });

            return relevancy;
        }

        const filteredAndRankedObjects = jsonArray
            .filter(obj => {
                const description = obj.position + " " + obj.description;
                const company = obj.company;

                if (description || company) {
                    const keywords = searchQuery.toLowerCase().split(' ');

                    return keywords.every(keyword => {
                        return (
                            (description && description.toLowerCase().includes(keyword)) ||
                            (company && company.toLowerCase().includes(keyword))
                        );
                    });
                }

                return false;
            })
            .map(obj => ({
                ...obj,
                relevancy: calculateRelevancy(obj.description, obj.company, obj.position, searchQuery)
            }))
            .sort((a, b) => b.relevancy - a.relevancy);

        return filteredAndRankedObjects;
    }
    const getDescription = (jobListing) => {
        let short = jobListing.description
        console.log(jobListing.collapsed)
        if(!jobListing.collapsed) 
            short = short.substring(0,500)

        return escapedNewLineToLineBreakTag(short)
    }
    const escapedNewLineToLineBreakTag = (string) => {

        return string.split('\\n').map((item, index) => {
            return (index === 0) ? item : [<br key={index} />, item]
        })
    }
    const dba = getFirestore(app)
    const getData = async () => {
        let out = [];
        let qra;
        const jobsRef = collection(dba, "jobs")
        if (all) {
            qra = query(jobsRef, where("age", "<=", 1000))
        }
        else {
            qra = query(jobsRef, where("age", "<=", 1000))
            if (queryData.age !== -1 || queryData.age != '') {
                qra = query(jobsRef, where("age", "<=", queryData.age))
            }
        }
        const querySnapshot = await getDocs(qra);
        querySnapshot.forEach((doc) => {
            let data = doc.data()
            data["id"] = doc.id
            data["saved"] = getSavedListings().includes(doc.id)
            if(data["saved"] || all) {
                out.push(data)
            }
            else {
                if(!queryData.savedOnly) {
                    out.push(data)
                }
            }

        });
        const distance = (a, t) => Math.abs(t - a);
        out = out.sort((a, b) => distance(a.zipcode, all ? 20852 : queryData.zip) - distance(b.zipcode, all ? 20852 : queryData.zip));
        console.log(out)
        if (!all) {
            out = searchAndRankObjects(queryData.name, out)
        }
        console.log(out)
        return out


    }
    const refresh = () => {
        getData().then(out => {
            const specificIndices = [9, 7, 10];

            // Extract the specific entries to be moved to the front
            const specificEntries = specificIndices.map(index => out[index]);

            // Remove the specific entries from their original positions
            specificIndices.reverse().forEach(index => out.splice(index, 1));
            
            // Reinsert the specific entries at the beginning of the list
            out = [...specificEntries, ...out];
            setJobData(out)});
    }
    useEffect(() => {
        refresh()

    }, [])

    const [jobData, setJobData] = useState();
    const toggleListing = (id, index) => {
        let saved = getSavedListings();
        console.log("SAVED", saved)
        if (saved.includes(id)) {
            saved = saved.filter(item => item !== id);
            localStorage.setItem('saved', JSON.stringify(saved));
            let tmp = [...jobData];
            tmp[index].saved = false;
            setJobData(tmp);
        } else {
            saved.push(id);
            localStorage.setItem('saved', JSON.stringify(saved));
            let tmp = [...jobData];
            tmp[index].saved = true;
            setJobData(tmp);
        }
    };

    const getSavedListings = () => {
        const savedData = localStorage.getItem('saved');
        return savedData ? JSON.parse(savedData) : [];
    };
    return (
        <div>
            <div id='card'>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th />
                            <th>Company</th>
                            <th>Location</th>
                            <th>Job Description {'&'} Qualifications</th>
                            <th>Age</th>
                            <th>Type</th>
                            <th>Pay</th>

                        </tr>
                    </thead>
                    <tbody>
                        {jobData ? jobData.map((jobListing, index) => {
                            return jobListing && jobListing.company && (
                                <tr
                                // onClick={() => window.open(jobListing.website || '', '_blank' )}
                                >
                                    <td className="save-heart">{jobListing.saved ? (<AiFillHeart size={30} onClick={() => toggleListing(jobListing.id, index)} />) : (<AiOutlineHeart  size={30} onClick={() => toggleListing(jobListing.id, index)} />)}</td>
                                    <td className='company-name' data-label='Company'>  <a href={jobListing.website || ''}>{jobListing.company}</a></td>
                                    <td data-label='Location'><a href={`https://www.google.com/maps/place/${jobListing.location}`}>
                                        {jobListing.location}</a></td>
                                    <td data-label='Description' className="desc" style={{textAlign: 'left'}}>
                                        <b>{jobListing.position ? (<p><b>{jobListing.position}</b> </p>): ''}</b>
                                        {/* {getDescription(jobListing)} */}
                                        {escapedNewLineToLineBreakTag(jobListing.description.substring(0, collapsed[index] ? 999999 : 500))}
                                        {(jobListing.description).length > 500 && (
                                        <>
                                        {" "}
                                        <button onClick={()=>{
                                            console.log(collapsed)
                                            
                                            setCollapsed({...collapsed, [index]: !collapsed[index]})}} className="link-btn">Show {!collapsed[index] ? "more" : "less"}</button>         
                                         </>
                        )}
                                        <p />
                                        <center><Button href={jobListing.website}>Apply</Button></center>
                                        </td>
                                    <td data-label='Age'>{jobListing.age}+</td>
                                    <td data-label='Type'>{jobListing.type}</td>
                                    <td data-label='Pay'>{jobListing.pay}</td>

                                </tr>
                            )
                        }) : ''}
                    </tbody>
                </Table>

            </div>
        </div>
    )
}

export default JobsTable