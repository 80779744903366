import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import App from './App';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDV7wELPh8dTQixxXxNEh_VlVs5fBmolEY",
  authDomain: "earlypath-1.firebaseapp.com",
  projectId: "earlypath-1",
  storageBucket: "earlypath-1.appspot.com",
  messagingSenderId: "628152141834",
  appId: "1:628152141834:web:79fb4eedfe184e8e2db7f3",
  measurementId: "G-KXBDNW4VSZ"
};

// Initialize Firebase

ReactDOM.render(
  <React.StrictMode>
    <App fbConfig={firebaseConfig} />
  </React.StrictMode>,
  document.getElementById('root')
);
