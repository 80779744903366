/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { Button, Col, Container, FloatingLabel, Form, FormControl, InputGroup, Row } from 'react-bootstrap'
import {AiOutlineSearch} from 'react-icons/ai';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FilterSelector from './FilterSelector';
function SearchBar() {
  let navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  let lQ = {
    distance: parseFloat(searchParams.get("distance")) ? parseFloat(searchParams.get("distance")) : '' ,
    age: parseInt(searchParams.get("age")) ? parseInt(searchParams.get("age")) : '',
    name: (searchParams.get("name")) ? (searchParams.get("name")) : '',
    zip: parseInt(searchParams.get("zip")) ? parseInt(searchParams.get("zip")) : '',
    savedOnly: searchParams.get("savedOnly") === "true" ? searchParams.get("savedOnly") === "true" : false
  }
  const updateLQ = (param, value) => {
    let tmp = lQ
    tmp[param] = value || ''
    console.log(value)
    lQ = (tmp)
  }
  return (
    <div>
      {/* <center className='text-light'> <h2 style={{display: 'inline'}}>Search: </h2> <input className='search-bar'></input></center>  */}
      <Container className='g-2'>
          <Row className='text-dark d-inline-block subheader-text'>

          </Row>
          <Row className='g-1'>
            <Col md={8}>
              <FloatingLabel
               
                label='Job Description'
              >
                <Form.Control defaultValue={lQ.name} id='q-search-in' onChange={(e)=>updateLQ("name", e.target.value)} className='' type='name' placeholder='Search Job by name' />
              </FloatingLabel>
            </Col>
          
          <Col md={1}>
              <FloatingLabel label='Age'>
                <Form.Select defaultValue={lQ.age} aria-label='Floating label select example' onChange={(e)=>updateLQ("age", parseInt(e.target.value))}>
                  <option value='-1' >All</option>
                  <option value='13'>13</option>
                  <option value='14'>14</option>
                  <option value='15'>15</option>
                  <option value='16'>16</option>
                  <option value='17'>17</option>
                  <option value='18'>18</option>
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col >
              <FloatingLabel
               
                label='Zipcode'
              >
                <Form.Control defaultValue={lQ.zip} onChange={(e)=>updateLQ("zip", e.target.value)} id='q-search-in' className='' type='name' placeholder='Near?' />
              </FloatingLabel>
            </Col>
            <Col md={2}>
              <FloatingLabel
               
                label='Distance (mi.)'
              >
                <Form.Control defaultValue={lQ.distance} onChange={(e)=>updateLQ("distance", e.target.value)}id='q-search-in' className='' type='number' placeholder='distance' />
              </FloatingLabel>
            </Col>
          </Row>
          <Row className='my-2'>
            <Col style={{textAlign: 'left'}} sm={6} md={3}>
              <Form.Switch defaultChecked={lQ.savedOnly} label="Saved Listings Only?" onChange={(e)=>updateLQ("savedOnly", e.target.checked)}></Form.Switch>
            </Col>
            <Col style={{textAlign: 'right'}} >
              <a onClick={()=>{
                navigate(`/jobs?age=${lQ.age ?? ''}&name=${lQ.name ?? ''}&distance=${lQ.distance ?? 50}&zip=${lQ.zip ?? 20852}&savedOnly=${lQ.savedOnly ?? false}`, {replace: true})
                navigate(0)
              
                }}><Button variant='primary' type='submit'>
                Search
              </Button></a>
            </Col>
          </Row>
          <Row>
            <p></p>
          </Row>
        </Container>    
      
    </div>
  )
}

export default SearchBar