import React, { useState } from 'react'
import { useSpring, animated } from 'react-spring';
function CircleProgressBar({ circleWidth, percentage }) {
    const radius = 100;
    const dashArray = Math.PI * (radius) * 2;
    const { per } = useSpring({
        from: {
            per: 0
        },
        to: {
            per: percentage
        },
        config: { duration: 1000 }
    })


    const { dashOffset } = useSpring({
        from: {
            dashOffset: dashArray - (dashArray * 0) / 100,

        },
        to: {
            dashOffset: dashArray - (dashArray * percentage) / 100,

        },
        config: { duration: 1000 }
    })
    return (
        //  <div className='circle-stat'>
        //           <div className='circle-stat lower-ring'></div>
        //           <div className='circle-stat ring'></div>
        //           <div className='circle-stat back'></div>
        //  </div>

        <div className='prog-container'>
            <svg className='circle-stat' width={circleWidth} height={circleWidth} viewBox={`0 0 ${circleWidth} ${circleWidth}`}>
                <defs>
                    <linearGradient id="strokeGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stop-color="rgba(194,0,255,1)" />
                        <stop offset="100%" stop-color="rgba(59,42,255,1)" />


                    </linearGradient>
                </defs>
                {/* <circle
                    cx={circleWidth/ 2}
                    cy={circleWidth/ 2}
                    strokeWidth="2px"
                    r={radius}
                    className='circle-outline'

                />
                <circle
                    cx={circleWidth/ 2}
                    cy={circleWidth/ 2}
                    strokeWidth="2px"
                    r={radius - 30}
                    className='circle-outline'

                /> */}
                <circle
                    cx={circleWidth / 2}
                    cy={circleWidth / 2}
                    strokeWidth="20px"
                    r={radius}
                    className='circle-back'


                />
                <animated.circle
                    onlo
                    cx={circleWidth / 2}
                    cy={circleWidth / 2}
                    strokeWidth="20px"
                    r={radius}
                    className='circle-prog'
                    style={{
                        strokeDasharray: dashArray,
                        strokeDashoffset: dashOffset.to(dashOffset => dashOffset)
                    }}
                    stroke={"url(#strokeGradient)"}
                />
                <circle
                    cx={circleWidth / 2}
                    cy={circleWidth / 2}
                    strokeWidth="5px"
                    r={radius + 10}
                    className='circle-prog'
                    stroke='white'
                />
                <circle
                    cx={circleWidth / 2}
                    cy={circleWidth / 2}
                    strokeWidth="5px"
                    r={radius - 10}
                    className='circle-prog'
                    stroke='white'
                />
                <animated.line

x1 = {circleWidth / 2 + (radius - 10) * Math.cos(-(dashArray - (dashArray * percentage) / 100) / radius)}
y1 = {circleWidth / 2 + (radius - 10) * Math.sin(-(dashArray - (dashArray * percentage) / 100) / radius)}
x2 = {circleWidth / 2 + (radius + 10) * Math.cos(-(dashArray - (dashArray * percentage) / 100) / radius)}
y2 = {circleWidth / 2 + (radius + 10) * Math.sin(-(dashArray - (dashArray * percentage) / 100) / radius)}

                    stroke="white"
                    strokeWidth="5px"
                />
                <line

                    x1={circleWidth / 2 + radius + 10}
                    y1={circleWidth / 2}
                    x2={circleWidth / 2 + radius - 10}
                    y2={circleWidth / 2}
                    stroke="white"
                    strokeWidth="5px"
                />

            </svg>
            <h3 className='gradient-text circle-text'>{percentage}% <br /> <h5>of students</h5></h3>
        </div>
    )
}

export default CircleProgressBar