import React, { useEffect, useState } from 'react'
import { Container, Table } from 'react-bootstrap'

import { getFirestore, collection, getDocs, where, query } from "firebase/firestore";
import SearchBar from '../Components/SearchBar';
import '../jobs.css'
import { Parallax } from '@react-spring/parallax';
import JobsTable from './JobsTable';
import { useSearchParams } from 'react-router-dom';

function Jobs({app, type}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState({
    distance: parseFloat(searchParams.get("distance")) ? parseFloat(searchParams.get("distance")) : 50 ,
    age: parseInt(searchParams.get("age")) ? parseInt(searchParams.get("age")) : 18,
    name: (searchParams.get("name")) ? (searchParams.get("name")) : '',
    zip: parseInt(searchParams.get("zip")) ? parseInt(searchParams.get("zip")) : 20852,
    savedOnly: searchParams.get("savedOnly") === "true"
  });

  return  (
    <div>
        <Container className='jobs'>
            {/* {queryData && JSON.stringify(queryData)} */}
            <SearchBar queryData={searchParams.get("query")}/>
            
           
               <JobsTable app={app} queryData={query} type={type}/>
        </Container>
    </div>
  ) || (<div>Loading</div>)
}

export default Jobs