import React from 'react'
import { Container } from 'react-bootstrap'
import {Fade} from 'react-reveal'

function Story() {
  return (
    <div>
        <Container fluid className=' genPage primBlack'>
        <Container>
        <Fade>
       <h1> Our Story </h1> 
       </Fade>
       <Fade>
       <p>
      

We’re Nathan Chen, Ryan Tanenholz, and Ashley Hays, students of Bullis School and the creators of EarlyPath. We created this website to solve one problem: how can high school students find jobs and internship opportunities in a systematic way without needing help from personal connections? Through our own experiences, we both found that at our age, it's hard to seek out opportunities for yourself without knowing the right people. With EarlyPath, we aimed to give everyone a chance to display their skills and learn from real-world, hands-on experiences. As you navigate through EarlyPath, we encourage you to explore your interests, form new passions, and find the right opportunities for you.
        </p>
        <p>
        Happy searching,
        </p>
        <p>
        Nathan, Ryan, and Ashley <br></br>
        Co-Founders of EarlyPath
        </p>
        </Fade>
        </Container>
        </Container>
    </div>
  )
}

export default Story