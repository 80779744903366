import React from 'react'

function Poll() {
  return (
    <div>
        {window.location.replace('https://forms.gle/v9SzM1NDdPShevDZA')}
    </div>
  )
}

export default Poll