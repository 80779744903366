import './Components.css';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import NavbarTop from './Pages/NavbarTop';
import Jobs from './Pages/Jobs';
import Story from './Pages/Story';

import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import ContactUs from './Pages/Contact';
import { Col, Row } from 'react-bootstrap';
import SignInScreen from './Pages/LogInScreen';
import { useEffect, useState } from 'react';
import Poll from './Pages/Poll';
import EarlyPathBot from './Pages/EarlyPathBot';
import ResumeBuilder from './Pages/ResumeBuilder';

function App({fbConfig}) {
  const app = initializeApp(fbConfig);
  const [isSignedIn, setIsSignedIn] = useState(undefined); // Local signed-in state.
  
  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
      const unregisterAuthObserver = getAuth(app).onAuthStateChanged(user => {
      setIsSignedIn(!!user);
      });
      return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);
  return (
   
    <Router>
      <div>
        <NavbarTop isSignedIn={isSignedIn} />
        
      </div>
      <Routes className='content'>
        <Route path='/signin' element={<SignInScreen app={app} isSignedIn={isSignedIn}/>}></Route>
        <Route path='/contact' element={<ContactUs />}></Route>
        <Route path='/story' element={<Story />}></Route>
        <Route path='/poll' element={<Poll />}></Route>
        <Route path='/build-a-resume' element={<ResumeBuilder />}></Route>
        <Route path='/ai' element={<EarlyPathBot />}></Route>
        <Route path='/jobs' element={<Jobs app={app} />}></Route>
        <Route path='/' element={<Home app={app} />}></Route>
      </Routes>
      <div className='container-fluid bg-dark text-light centered p-3 footer'>
        <Row>
          <Col className='col-6'>All Rights Reserved</Col>
          <Col className='col-6'>
            {/* <a href='mailto: nathan_chen@bullis.org'>Contact</a> */}
            Contact: nathan@earlypath.org
          </Col>
        </Row>
      </div>
    </Router>
  );
}

export default App;
