import React from 'react'
import { Button, Col, Container, FloatingLabel, Form, InputGroup, Row } from 'react-bootstrap'

function Contact() {
  return (
    <Container fluid className='text-dark'>
      <Container className='p-5'>
      <Form>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="Enter email" />
        <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Subject</Form.Label>
        <Form.Control type="text" placeholder="Enter subject" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="lg">
        <Form.Label>Message</Form.Label>
        <Form.Control type="text" label="" as="textarea" rows={3} />
      </Form.Group>
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
      <p />
      <h1>Want to message us directly?</h1>
      <Row >
      <p />
        <Col md={12}>

        <h6>Email us directly:</h6>

        </Col>
        <Col md={12}>
        <h9>
        nathan_chen@bullis.org <br />
        ryan_tanenholz@bullis.org <br />
        ashley_hays@bullis.org</h9>
        </Col>
        
      </Row>
      </Container>
      </Container>
  )
}

export default Contact