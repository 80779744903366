import React from 'react'
import { Container, Row } from 'react-bootstrap'

function EarlyPathBot() {
  return (
    <Container fluid className="genPage bot-container" style={{minHeight: '100vh'}}>
    <Container className='centered'>
    <Row className='m-2 text-light'>
    <h1 >Early Path AI</h1>
    </Row>
    <hr className='text-light' />
    <Row className=''>
    <iframe
      src="https://app.gpt-trainer.com/gpt-trainer-widget/9944380072094b83bd6c3254eef5d499"
      className='bot'
      width='30%'
      style={{ height: '80vh' }}  // Set the height in viewport units (vh)
      frameBorder="0"
    ></iframe>
  </Row>
    </Container>
    </Container>

    
  )
}

export default EarlyPathBot